import React, { useState, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { withBreakpoints } from 'gatsby-plugin-breakpoints';
import { Link } from "gatsby";
import { useSpring, a } from "react-spring";

import { Welcome } from "../Welcome/Welcome";
import { Markets } from "../Markets/Markets";
import { Video } from "../Video/Video";
import { Carousel } from "../Carousel/Carousel";
import { StyledCarouselItem } from "../Carousel/style";
import OverLay from "../Overlay/Overlay";
import { Footer } from "../Footer/Footer";
import { Star } from "../Star/Star";
import { Decorates } from "../Decorates/Decorates";

import {
    StyledMainWrapper,
    StyledImgWrapepr,
    StyledPageWrapper,
    StyledBorderIndexPage,
    StyledDecoratesWrapper,
    StyledCarouselWrapper,
    StyledOverlayCarouselBtn,
    StyledTitle,
    StyledPlayButtonWrapper,
    StyledButtonBg,
    StyledButton,
    StyledMouseWrapper
} from "./style";

import img1 from "../../images/gallery/1.png";
import img2 from "../../images/gallery/2.png";
import img3 from "../../images/gallery/3.png";
import img4 from "../../images/gallery/4.png";
import img5 from "../../images/gallery/5.png";

const firstGameStyle = {
    position: "absolute",
    top: "-338px",
    left: "-545px",
    opacity: 1,
    transform: "rotate(5deg) rotateY(0deg)"
};

const secondGameStyle = {
    position: "absolute",
    top: "-458px",
    left: "698px",
    transform: "rotate(-359deg)",
    width: "850px"
};

const spinningStyle = {
    position: "absolute",
    top: "374px",
    right: "92px",
    zIndex: 0
};

const owlStyle = {
    position: "absolute",
    bottom: "788px",
    left: "-72px",
    opacity: "0.7",
    transform: "scale(.8)",
    zIndex: 1
};

const IndexPageComponent = ({breakpoints}) => {
    const slider = useRef();
    const [isOpenOverlay, setIsOpenOverlay] = useState(false);
    const isTabletOrMobileDevice = breakpoints.l;
    const isPortrait = breakpoints.portrait;

    const [style, animate] = useSpring(() => ({
        opacity: 0,
        display: "none"
    }));

    const { opacity, display } = style;

    const items = [img1, img2, img3, img4, img5];

    const handleClick = async index => {
        setIsOpenOverlay(!isOpenOverlay);
        slider.current.slickGoTo(index);
        if (isOpenOverlay) {
            await animate({ opacity: 0 });
            await animate({ display: "none" });
        } else {
            await animate({ display: "block" });
            await animate({ opacity: 1 });
        }
    };

    return (
        <StyledPageWrapper withoutPadding>
            <StyledMainWrapper>
                <StyledImgWrapepr>
                    <StaticImage className="pointerEventsNone cinderella" src="../../images/cinderella.png" alt="cinderella" placeholder="blurred" />
                    <StaticImage className="pointerEventsNone gameLogo" src="../../images/logo.png" alt="logo" placeholder="blurred" />
                    <StaticImage className="pointerEventsNone prince" src="../../images/prince.png" alt="prince" placeholder="blurred" />
                </StyledImgWrapepr>
                <StyledBorderIndexPage>
                    {
                        !isTabletOrMobileDevice && (
                        <StyledMouseWrapper>
                            <StaticImage className="pointerEventsNone" src="../../images/mouse.png" alt="mouse" placeholder="blurred" />
                        </StyledMouseWrapper>
                        )
                    }
                    <StyledDecoratesWrapper>
                        <Decorates firstGameStyle={firstGameStyle} secondGameStyle={secondGameStyle} spinningStyle={spinningStyle} owlStyle={owlStyle} />
                        <Welcome />
                        <Markets />
                        <Video />
                        <StyledTitle>
                            <Star />
                            <h2>screenshots</h2>
                            <Star />
                        </StyledTitle>
                        <StyledCarouselWrapper>
                            <Carousel slidesToShow={isTabletOrMobileDevice && isPortrait ? 2 : 3}>
                                {items.map((item, index) => (
                                    <StyledCarouselItem onClick={() => handleClick(index)} key={index}>
                                        <img src={item} alt={`item-${index}`} placeholder="blurred" />
                                    </StyledCarouselItem>
                                ))}
                            </Carousel>
                        </StyledCarouselWrapper>
                        <a.div
                            style={{
                                opacity: opacity.to(o => o),
                                display: display.to(d => d),
                                zIndex: 100,
                                position: "fixed"
                            }}
                        >
                            <OverLay onOutsideClick={handleClick} isVisible={isOpenOverlay}>
                                <Carousel
                                    slidesToShow={1}
                                    nextArrow={<StyledOverlayCarouselBtn className="prev" isBigButton={isTabletOrMobileDevice && isPortrait}/>}
                                    prevArrow={<StyledOverlayCarouselBtn className="next" isBigButton={isTabletOrMobileDevice && isPortrait}/>}
                                    sliderRef={slider}
                                >
                                    {items.map((item, index) => (
                                        <StyledCarouselItem key={index}>
                                            <img src={item} alt={`item-${index}`} placeholder="blurred" />
                                        </StyledCarouselItem>
                                    ))}
                                </Carousel>
                            </OverLay>
                        </a.div>
                        <Footer showButtons />
                    </StyledDecoratesWrapper>
                </StyledBorderIndexPage>
            </StyledMainWrapper>
        </StyledPageWrapper>
    );
};

export default withBreakpoints(IndexPageComponent);
