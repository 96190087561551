import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import { StyledMarkets, StyledMarketsList } from "./style";

export const Markets = () => {
    const isShowAmazon = false;

    return (
        <StyledMarkets>
            <StyledMarketsList isShowAmazon={isShowAmazon}>
                <li>
                </li>
                <li>
                </li>
                {isShowAmazon && (
                    <li>
                        <Link to="/">
                            <StaticImage className="itemImg" src="../../images/store/amazon.png" alt="Amazon App Store" placeholder="blurred" />
                        </Link>
                    </li>
                )}
            </StyledMarketsList>
        </StyledMarkets>
    )
};
